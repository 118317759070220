import debug from "@cher-ami/debug"
import { Stack } from "~/libs/compose"
import AboutPage from "~/pages/aboutPage/AboutPage"
import HomePage from "~/pages/homePage/HomePage"
import NotFoundPage from "~/pages/notFoundPage/NotFoundPage"
import SinglePostPage from "~/pages/singlePostPage/SinglePostPage"
import Header from "../header/Header"
import SmoothScrollManager from "~/services/SmoothScrollManager"
import RafService from "~/services/RafService"

const componentName = "App"
const log = debug(`front:${componentName}`)

/**
 * @name App
 */
export default class App extends Stack {
	static attrName = "App"

	navLinkAbout: HTMLAnchorElement
	lastPathName: string

	constructor(props) {
		super(props)
	}

	// ----------------------------------------------------------------------------- COMPONENTS

	public header = this.add(Header)

	// ----------------------------------------------------------------------------- PAGES

	addPages() {
		return {
			HomePage,
			AboutPage,
			SinglePostPage,
			NotFoundPage
		}
	}

	// ----------------------------------------------------------------------------- LIFECYCLE

	public mounted() {
		log("App mounted")

		SmoothScrollManager.init()
		RafService.start()

		this.header.playIn(200)

		document.body.style.visibility = "visible"

		// register last url at init
		this.lastPathName = this.history.location.pathname

		// get about link
		this.navLinkAbout = document.querySelector(".nav__item-link--about")

		// listen history change to manage about link state
		this.emitter.on("history:change", (pathname) => {
			log("> history:change", pathname)
			this.manageAboutLinkState(pathname)
		})
	}

	public unmounted() {}

	// --------------------------------------------------------------------------- ABOUT LINK STATE

	private manageAboutLinkState(pathname: string) {
		if (pathname === "/a-propos") {
			// get current page
			this.navLinkAbout.href = this.navLinkAbout.dataset.pageTransitionUrl =
				this.lastPathName || "/"

			this.navLinkAbout.textContent = "Retour"
		} else {
			this.navLinkAbout.href = this.navLinkAbout.dataset.pageTransitionUrl = "/a-propos"
			this.navLinkAbout.textContent = "À propos"
		}
		this.lastPathName = pathname
		log("manageAboutLinkState pathname", pathname)
	}

	// --------------------------------------------------------------------------- PAGE TRANSITION

	// @ts-ignore
	protected pageTransitions = async (currentPage, newPage, complete) => {
		// New page is already inject in DOM at this step, we need to manage it manually
		newPage.$pageRoot.style.visibility = "hidden"

		if (currentPage) {
			// start play out current page
			await currentPage.playOut()
		}

		complete()

		// back to top
		SmoothScrollManager.lenis.scrollTo(0, { immediate: true })
		SmoothScrollManager.currentScroll = 0

		// then play in new page
		newPage.$pageRoot.style.visibility = "visible"
		await newPage.playIn()
	}
}
