import debug from "@cher-ami/debug"
import { Component } from "~/libs/compose"
import mitt, { Emitter } from "mitt"
import gsap from "gsap"
import { isMobile } from "~/helpers/isMobile"

const componentName = "PostSliderButton"
const log = debug(`front:${componentName}`)

export type PostSliderButtonEvents = {
	click: { event: MouseEvent; direction: 1 | -1 }
}

/**
 * @name PostSliderButton
 */
export default class PostSliderButton extends Component<{}> {
	static attrName = componentName

	private classNames: string
	public direction: "prev" | "next"

	public parentElement: HTMLElement

	public emitter: Emitter<PostSliderButtonEvents> = mitt<PostSliderButtonEvents>()
	lastScrollY: number = 0
	scrollStateTl: gsap.core.Timeline
	isButtonAtBottom: boolean = false
	isAnimating: boolean = true

	// ----------------------------------------------------------------------------- LIFECYCLE

	mounted() {
		this.attachEvents()

		// get direction from data attribute
		this.direction = this.$root.dataset.direction as "prev" | "next"

		// get class names
		this.classNames = this.$root.className

		// get parent element for button
		this.parentElement = this.$root.parentElement
		// move to body child to avoid parent transform because button is fixed
		document.body.appendChild(this.$root)

		log(`> mounted`)

		this.scrollStateTl = gsap
			.timeline({
				paused: true,
				defaults: {
					ease: "power2.inOut",
					duration: 0.25
				}
			})
			.to(this.$root, {
				opacity: 0,
				scale: 0.25
			})
			.set(this.$root, {
				top: "calc(100dvh - 27.5rem - 16rem)"
			})
			.to(this.$root, {
				opacity: 1,
				scale: 1
			})
	}

	unmounted() {
		this.detachEvents()

		// remove from body
		this.parentElement.appendChild(this.$root)
	}

	// ----------------------------------------------------------------------------- EVENTS

	attachEvents() {
		this.$root.addEventListener("click", this.onClick)
		// listen to scroll event
		if (isMobile()) window.addEventListener("scroll", this.onScroll)
	}

	detachEvents() {
		this.$root.removeEventListener("click", this.onClick)
		// remove scroll event listener
		if (isMobile()) window.removeEventListener("scroll", this.onScroll)
	}

	onClick = (e) => {
		this.emitter.emit("click", {
			event: e,
			direction: this.direction === "next" ? 1 : -1
		})
	}

	onScroll = (e) => {
		if (this.isAnimating) return
		// get scroll direction
		const scrollDirection = window.scrollY > this.lastScrollY ? "down" : "up"
		const treshold = 30

		// if we are scrolling down from the top, hide the button and show it at the bottom of the viewport
		// else show the button at the initial position
		if (
			scrollDirection === "down" &&
			window.scrollY > treshold - 10 &&
			!this.isButtonAtBottom
		) {
			this.scrollStateTl.play()
			this.isButtonAtBottom = true
		}
		if (scrollDirection === "up" && window.scrollY < treshold && this.isButtonAtBottom) {
			this.scrollStateTl.reverse()
			this.isButtonAtBottom = false
		}

		this.lastScrollY = window.scrollY
	}

	// ----------------------------------------------------------------------------- ANIMATION

	override playIn(delay): void {
		this.isAnimating = true
		const root = document.getElementsByClassName(this.classNames)[0]
		gsap.to(root, {
			scale: 1,
			duration: 0.5,
			ease: "power4.out",
			delay: delay || 0,
			onComplete: () => {
				setTimeout(() => {
					this.isAnimating = false
				}, 100)
			}
		})
	}

	override playOut(): void {
		this.isAnimating = true
		const root = document.getElementsByClassName(this.classNames)[0]
		gsap.to(root, {
			scale: 0,
			duration: 0.3,
			ease: "power3.out",
			onComplete: () => {
				this.isAnimating = false
			}
		})
	}
}
