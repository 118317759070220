import debug from "@cher-ami/debug"
import { Component } from "~/libs/compose"

import gsap from "gsap"
import Vec3 from "~/libs/vec3"
import { isMobile } from "~/helpers/isMobile"

const componentName = "PostHand"
const log = debug(`front:${componentName}`)

type TStaticProps = {}

/**
 * @name PostHand
 */
export default class PostHand extends Component<TStaticProps> {
	static attrName = "PostHand"
	public isFirstPlayin: boolean = true
	public canAnimateScroll: boolean = false

	public elements = {}

	// ----------------------------------------------------------------------------- LIFECYCLE

	mounted() {
		// init anim
		log("> mounted")
		this.prepare()

		// this.playIn()
		this.updateLogo("/assets/icons/icon-mcdonalds.svg")
		this.attachEvents()
	}

	unmounted() {
		this.detachEvents()
	}

	// ----------------------------------------------------------------------------- UPDATE LOGO

	// method to update the logo in svg image #icon-image from a src param
	updateLogo(src: string): void {
		const $iconImage = this.$root.querySelector("#icon-image") as HTMLImageElement
		$iconImage.setAttribute("href", src)
	}

	// ----------------------------------------------------------------------------- EVENTS

	attachEvents() {
		// Scroll event listener
		window.addEventListener("scroll", this.onScroll)
	}

	detachEvents() {
		// Scroll event listener
		window.removeEventListener("scroll", this.onScroll)
	}

	onScroll = () => {
		this.updateHandScrollPosition()
	}
	updateHandScrollPosition() {
		if (!this.canAnimateScroll) return

		// Get the scroll position as a percentage of the page height
		const scrollPercent = window.scrollY / 800

		// Calculate the translation amount based on the scroll percentage
		const maxTranslation = 33 // Max translation percentage
		const translationY = maxTranslation * scrollPercent * 0.9
		const translationX = (isMobile() ? 36 : 18) * scrollPercent

		// Calculate rotation for hand from 0 to 1
		const rotation = scrollPercent

		gsap
			.timeline({
				defaults: {
					duration: 0.4,
					ease: "power2.out"
				},
				onUpdate: () => {
					// log("update on scroll")
				}
			})
			.to("#Main", { rotate: rotation * 5 })
			.to("#group-hand", { rotate: rotation * (isMobile() ? 45 : 33) }, "<")
			.to("#card", { rotate: rotation * (isMobile() ? 16 : 10) }, "<")
			.to(this.$root, { yPercent: translationY, xPercent: translationX }, "<")

		// if scrolly > window inner height hide root
		if (window.scrollY > window.innerHeight / (isMobile() ? 1.57 : 1.7)) {
			gsap.set(this.$root, { opacity: 0 })
		} else {
			gsap.to(this.$root, { opacity: 1 })
		}
	}

	// ----------------------------------------------------------------------------- ANIMATION

	private prepare(): void {}

	override playIn(delay?): void {
		this.canAnimateScroll = false

		this.resetTweens()

		gsap.set(this.$root, { opacity: 0 })

		// gsap.to(this.$root, { opacity: 1, duration: 0.2 })

		// new timeline animate each group of the hand svg
		const tl = gsap.timeline({
			paused: true
		})
		const duration = isMobile() ? 1.25 : 1
		const rotationForce = isMobile() ? 2 : 0.8
		const ease = "elastic.out(1,0.66)"

		tl.fromTo("#Main", { rotate: -35 * rotationForce }, { rotate: 0, duration, ease }, 0)
			// .set("#group-hand", { transformOrigin: "34% 41%" }, 0)
			.fromTo(
				"#group-hand",
				{ rotate: -20 * rotationForce },
				{ rotate: 0, duration, ease },
				0
			)
			.fromTo("#card", { rotate: -17 * rotationForce }, { rotate: 0, duration, ease }, 0)

		// play after delay
		gsap.delayedCall(delay, () => {
			gsap.set(this.$root, { opacity: 1 })
			tl.play().then(() => {
				if (this.isFirstPlayin) {
					this.canAnimateScroll = true
					this.isFirstPlayin = false
				}
			})
		})
	}

	override playOut(): GSAPTimeline {
		this.canAnimateScroll = false
		const rotationForce = isMobile() ? 2 : 1

		this.resetTweens()
		// new timeline animate each group of the hand svg
		const tl = gsap.timeline({
			paused: true,
			onComplete: () => {
				this.canAnimateScroll = true
			}
		})
		const duration = 1

		// reset $root translate
		tl.set(this.$root, { yPercent: 0, xPercent: 0 })

		// rotate 45° svg #Main group

		tl.to(
			"#Main",
			{ rotate: -39 * rotationForce, duration, ease: "elastic.out(1,0.66)" },
			0
		)
			.to(
				"#group-hand",
				{ rotate: -20 * rotationForce, duration, ease: "elastic.out(1,0.66)" },
				0
			)
			.to(
				"#card",
				{ rotate: -20 * rotationForce, duration, ease: "elastic.out(1,0.66)" },
				0
			)
			.to(this.$root, { opacity: 0, duration: 0.5 }, 0)

		return tl.play()
	}

	private resetTweens(): void {
		gsap.killTweensOf("#Main, #group-hand, #card")
		gsap.killTweensOf(this.$root)
	}

	// override playIdleAnimation(): void {

	// }
}
