import debug from "@cher-ami/debug"
import gsap from "gsap"
import { Component } from "~/libs/compose"
import mitt, { Emitter } from "mitt"
import { Interpol, Timeline } from "@wbe/interpol"

export type PostSlideEvents = {
	"nav:change": { index: number }
}

const componentName = "PostNavigationStars"
const log = debug(`front:${componentName}`)

type TStaticProps = {}

// TODO: make generic method to share with introStars
/**
 * @name PostNavigationStars
 */
export default class PostNavigationStars extends Component<TStaticProps> {
	static attrName = componentName
	public emitter: Emitter<PostSlideEvents> = mitt<PostSlideEvents>()
	public articleBodyResizeObserver: ResizeObserver
	public currentIndex: number

	public elements = {
		$links: this.findAll<HTMLAnchorElement[]>("link"),
		$starIcons: this.findAll<HTMLElement[]>("icon"),
		$currentIndex: this.find<HTMLDivElement>("current-index")
	}

	// ----------------------------------------------------------------------------- LIFECYCLE

	mounted() {
		// init current index to dom data attribute or 0
		this.currentIndex = this.$root.dataset.currentIndex
			? +this.$root.dataset.currentIndex
			: 0
		// prepare the items
		this.prepare()
		// attach events
		this.attachEvents()

		log("> mounted: ")
	}

	unmounted() {
		this.detachEvents()

		// hide stars
		this.elements.$starIcons.forEach((el, i) => {
			el.style.transform = "scale(0)"
		})
		this.elements.$currentIndex.style.opacity = "0"
	}

	// ----------------------------------------------------------------------------- SETUP

	private prepare(): void {
		this.setupStarsPosition()
	}

	private setupStarsPosition = (): void => {
		const radius = this.getStarsRadius()

		// Calculate the angle at which the star should be positioned & set the transform
		this.elements.$links.forEach((star, i) => {
			const angle = (i / this.elements.$links.length) * Math.PI * 2 - Math.PI / 2
			let position = this.getStarCirlePosition(angle, radius)
			star.style.transform = `translate3D(${position.x}px, ${position.y}px, 0)`
		})
	}

	// ----------------------------------------------------------------------------- EVENTS

	attachEvents() {
		this.elements.$links.forEach((el) => el.addEventListener("click", this.onClickedLink))
		// add resize event
		window.addEventListener("resize", this.setupStarsPosition)
	}

	detachEvents() {
		this.elements.$links.forEach((el) =>
			el.removeEventListener("click", this.onClickedLink)
		)
		// remove resize event
		window.removeEventListener("resize", this.setupStarsPosition)
	}

	// ----------------------------------------------------------------------------- NAV CONTROL

	update = (index: number) => {
		if (index === this.currentIndex) return
		this.currentIndex = index
		this.updateLinks(index)
		this.updateIndexLabel(index)
		this.emitter.emit("nav:change", { index })
	}

	// add --current class to the current link and remove it from the others
	updateLinks = (index: number) => {
		this.elements.$links.forEach((el, i) => {
			el.classList.toggle("is-current", i === index)
		})
	}

	updateIndexLabel = async (index: number) => {
		await this.animateToggleCurrentIndex(-1)
		this.elements.$currentIndex.textContent = `${index + 1}`
		await this.animateToggleCurrentIndex(1)
	}

	// ----------------------------------------------------------------------------- LINK CONTROL

	onClickedLink = (e: MouseEvent) => {
		e.preventDefault()
		e.stopPropagation()
		const index = +(e.currentTarget as HTMLAnchorElement).dataset.index
		this.update(index)
	}

	// ----------------------------------------------------------------------------- ANIMATION

	override playIn(delay?): void {
		this.playInStars(delay)
		this.animateToggleCurrentIndex(1, delay)
	}

	private playInStars = (delay = 0) => {
		delay = delay || 0
		// Create a timeline instance
		const tl = new Timeline()
		log("> playIn")

		// Animate the stars reveal scale and rotation
		this.elements.$starIcons.forEach((el, i) => {
			tl.add(
				{
					el,
					props: {
						scale: [0, 1],
						rotate: [-45, 0, "deg"]
					},
					duration: 700,
					ease: "power2.out"
				},
				delay + i * 90
			)
		})

		// Animate the stars position on the circle in a loop
		const angleOffset = Math.PI / 2
		this.elements.$links.forEach((el, i) => {
			const angle = (i / this.elements.$links.length) * Math.PI * 2 - Math.PI / 2
			const radius = this.getStarsRadius()

			const tween = new Interpol({
				paused: true,
				props: {
					angle: [angle - angleOffset, angle]
				},
				duration: 1000,
				ease: "power4.out",
				onUpdate: (props) => {
					const position = this.getStarCirlePosition(props.angle, radius)
					el.style.transform = `translate3D(${position.x}px, ${position.y}px, 0)`
				},
				onComplete: () => {}
			})
			// const repeat = () => {
			// 	tween.refreshComputedValues()
			// 	tween.play().then(repeat)
			// }
			setTimeout(() => {
				tween.play()
			}, delay)
		})
	}

	// fade in top current index with Interpol
	private animateToggleCurrentIndex = (direction: -1 | 1 = 1, delay = 0) => {
		const tween = new Interpol({
			el: this.elements.$currentIndex,
			paused: true,
			props: {
				opacity: direction === 1 ? [0, 1] : [1, 0],
				y: direction === 1 ? [-33, 0, "%"] : [0, 33, "%"]
			},
			duration: direction === 1 ? 600 : 400,
			delay,
			ease: `power3.${direction === 1 ? "out" : "in"}`
		})
		return tween.play()
	}

	// ----------------------------------------------------------------------------- HELPERS

	// Function to calculate the position on the circle
	// FIXME: on almost square screen the stars are not well positioned
	private getStarCirlePosition = (angle, radius) => {
		return {
			x: Math.cos(angle) * radius + radius,
			y: Math.sin(angle) * radius + radius
		}
	}

	private getStarsRadius = () => {
		return this.$root.clientWidth / 2 - this.elements.$links[0].clientWidth / 2
	}
}
