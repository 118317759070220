export default (el: HTMLElement, options?: Options) => {
	// Store the original HTML
	const originalHtml = el.innerHTML
	if (!originalHtml) {
		return
	}

	const tokens = tokenizeEl(el)
	el.innerHTML = ""
	el.appendChild(tokens)

	const lines = getLineElements(getLines(el), options ? options.markup : undefined)
	el.innerHTML = ""
	el.appendChild(lines)

	// Returns a function to revert the split
	return () => {
		el.innerHTML = originalHtml
	}
}

type LinesMapType = {
	[offset: string]: DocumentFragment
}

export interface Options {
	markup?: string
}

export const tokenizeEl: (el: HTMLElement) => DocumentFragment = (el: HTMLElement) => {
	const fragment = document.createDocumentFragment()

	function processNode(node: Node, parentFragment: DocumentFragment) {
		if (node.nodeType === Node.TEXT_NODE) {
			const words = (node.textContent || "").split(" ")
			words.forEach((word, index) => {
				const wordSpan = document.createElement("span")
				wordSpan.textContent = word + (index < words.length - 1 ? " " : "") // Preserve white space between words
				parentFragment.appendChild(wordSpan)
			})
		} else if (node.nodeType === Node.ELEMENT_NODE) {
			const element = node as HTMLElement

			// Handle <br> elements specifically
			if (element.tagName.toLowerCase() === "br") {
				parentFragment.appendChild(element.cloneNode())
			} else {
				const shallowClone = element.cloneNode(false) as HTMLElement
				parentFragment.appendChild(shallowClone)
				element.childNodes.forEach((child) => {
					// @ts-ignore
					processNode(child, shallowClone)
				})
			}
		}
	}

	el.childNodes.forEach((child) => processNode(child, fragment))

	return fragment
}

export const getLines = (tokenizedEl: HTMLElement): LinesMapType => {
	const linesMap: LinesMapType = {}

	function processElement(element: HTMLElement) {
		const offsetTop = element.offsetTop.toString()
		if (!linesMap[offsetTop]) {
			linesMap[offsetTop] = document.createDocumentFragment()
		}
		linesMap[offsetTop].appendChild(element)
	}

	Array.from(tokenizedEl.childNodes).forEach((node) => {
		if (node instanceof HTMLElement) {
			processElement(node)
		}
	})

	return linesMap
}

export const getLineElements = (linesMap: LinesMapType, markup?: string) => {
	const fragment = document.createDocumentFragment()

	Object.keys(linesMap)
		.sort((a, b) => parseInt(a, 10) - parseInt(b, 10))
		.forEach((offsetTop) => {
			const lineFragment = linesMap[offsetTop]
			const lineWrapper = document.createElement("span")
			lineWrapper.classList.add("line-wrapper")

			const lineElement = document.createElement("span")
			lineElement.classList.add("line")
			lineElement.appendChild(lineFragment)

			lineWrapper.appendChild(lineElement)
			fragment.appendChild(lineWrapper)
		})

	return fragment
}
