import debug from "@cher-ami/debug"
import { Component } from "~/libs/compose"
import PostVoteDataViz from "../postVoteDataViz/PostVoteDataViz"
import mitt, { Emitter } from "mitt"
import postsNavigationService from "~/services/PostsNavigationService"

const componentName = "PostSlide"
const log = debug(`front:${componentName}`)

type TStaticProps = {}

export type PostSlideEvents = {
	"article:resize": { width: number; height: number }
}

/**
 * @name PostSlide
 */
export default class PostSlide extends Component<TStaticProps> {
	static attrName = componentName

	public emitter: Emitter<PostSlideEvents> = mitt<PostSlideEvents>()

	public articleBodyResizeObserver: ResizeObserver
	public isCurrent: boolean

	public dataviz = this.add(PostVoteDataViz)

	public elements = {
		$content: this.find<HTMLDivElement>("content")
	}
	public url: string
	public logoSrc: string

	// ----------------------------------------------------------------------------- LIFECYCLE

	mounted() {
		// // box width is the width of the slide
		// this.boxWidth = this.elements.$slides[0].offsetWidth
		// this.translateXOffset = this.boxWidth + this.gap
		// this.initSlider()
		this.url = this.$root.dataset.url
		this.logoSrc = this.$root.dataset.logoSrc
		this.attachEvents()

		log("> mounted: " + this.url)
	}

	unmounted() {
		this.detachEvents()
	}

	// ----------------------------------------------------------------------------- EVENTS

	attachEvents() {
		//this.elements.$buttonNext.addEventListener("click", this.next.bind(this))
		// this.elements.$buttonPrev.addEventListener("click", this.prev.bind(this))
		const link = this.$root.querySelector(".post-article__push-link")
		link.addEventListener("click", this.onClickNextLink.bind(this))

		// TODO: check for only current slide and not all slides
		//  resize observer for .post-article
		this.articleBodyResizeObserver = new ResizeObserver((entries) => {
			for (const entry of entries) {
				const { width, height } = entry.contentRect
				this.emitter.emit("article:resize", { width, height })
			}
		})
		this.articleBodyResizeObserver.observe(this.$root)
	}

	detachEvents() {
		// this.elements.$buttonNext.removeEventListener("click", this.next.bind(this))
		// this.elements.$buttonPrev.removeEventListener("click", this.prev.bind(this))

		this.$root
			.querySelector(".post-article__push-link")
			.removeEventListener("click", this.onClickNextLink.bind(this))

		// remove resize observer
		this.articleBodyResizeObserver.disconnect()
	}

	onClickNextLink(e: MouseEvent) {
		e.stopPropagation()
		e.preventDefault()

		const nextIndex =
			(postsNavigationService.currentIndex + 1) % postsNavigationService.totalPosts
		postsNavigationService.updateCurrentPost(nextIndex)
	}

	// ----------------------------------------------------------------------------- SLIDE LOGIC

	// ----------------------------------------------------------------------------- ANIMATION

	override playIn(delay): void {
		// Create a timeline instance
		// const tl = new Timeline({
		// 	onComplete: () => console.log("Timeline is complete")
		// })
		// ;[this.elements.$nav, this.elements.$logo].forEach((el) =>
		// 	tl.add(
		// 		{
		// 			el,
		// 			props: {
		// 				y: [102, 0, "%"]
		// 			},
		// 			duration: 700,
		// 			ease: "power3.out"
		// 		},
		// 		delay
		// 	)
		// )
	}
}
