import Lenis from "@studio-freight/lenis"

import debug from "@cher-ami/debug"
import RafService, { ERafExecuteOrder, IRafEvent } from "./RafService"
const log = debug(`front:SmoothScrollManager`)

class SmoothScrollManager {
	public lenis: Lenis
	public currentScroll = 0
	protected isStart = false
	public scrollDirection = 1

	// --------------------------------------------------------------------------- INIT

	public init() {
		if (this.lenis) return
		this.lenis = new Lenis({
			duration: 1.2,
			easing: (t) => (t === 1 ? 1 : 1 - Math.pow(2, -10 * t)), // https://easings.net
			orientation: "vertical",
			smoothWheel: true,
			// @ts-ignore
			smoothTouch: false,
			touchMultiplier: 2
		})
		this.lenis.on("scroll", this.onScroll)
		this.lenis.scrollTo(0, {
			immediate: true
		})
		RafService.addListener({
			function: this.lenisRaf,
			executeOrder: ERafExecuteOrder.LENIS,
			name: "Lenis"
		})
	}

	lenisRaf = (e: IRafEvent) => {
		this.lenis.raf(e.time)
	}
	// --------------------------------------------------------------------------- PUBLIC METHODS

	public stop() {
		if (!this.isStart) {
			log("isStart is already false, return")
			return
		}
		this.isStart = false
		this.lenis.stop()
	}

	public start() {
		if (this.isStart) {
			log("isStart is already true, return")
			return
		}
		this.isStart = true
		this.lenis.start()
	}

	private onScroll = (e) => {
		this.currentScroll = e.scroll
		this.scrollDirection = e.direction
	}

	public destroy() {
		this.lenis.destroy()
		this.lenis = null
		RafService.removeListener(this.lenis.raf)
	}
}

export default new SmoothScrollManager()
