import debug from "@cher-ami/debug"
const log = debug(`front:defaultTransitions`)

const yValue = 100
const duration = 0.75

export const defaultPlayIn = (
	$root?: HTMLElement,
	comeFrom?: string,
	resolve?: () => void
): void => {
	log("dir comeFrom:", comeFrom)
	$root.style.opacity = "0"

	const animation = $root.animate([{ opacity: 0 }, { opacity: 1 }], {
		duration: duration * 0.75 * 1000,
		easing: "ease-out"
	})

	animation.onfinish = () => {
		$root.style.opacity = "1"
		resolve?.()
	}
}

export const defaultPlayOut = (
	$root?: HTMLElement,
	goTo?: string,
	resolve?: () => void
): void => {
	log("dir goTo: ", goTo)
	$root.animate([{ opacity: 1 }, { opacity: 0 }], {
		duration: duration * 1000,
		easing: "ease-out"
	}).onfinish = resolve
}

export const defaultPlayInTranslate = (
	$root?: HTMLElement,
	comeFrom?: string,
	resolve?: () => void
): void => {
	$root.style.transform = `translateY(-${yValue}%)`

	const animation = $root.animate(
		[{ transform: `translateY(${yValue}%)` }, { transform: "translateY(0%)" }],
		{
			duration: duration * 1000,
			easing: "ease-out"
		}
	)

	animation.onfinish = () => {
		$root.style.transform = "translateY(0%)"
		resolve?.()
	}
}

export const defaultPlayOutTranslate = (
	$root?: HTMLElement,
	goTo?: string,
	resolve?: () => void
): void => {
	log("dir goTo: ", goTo)
	// TOOD: replace with interpol
	const animation = $root.animate(
		[{ transform: "translateY(0%)" }, { transform: `translateY(-${yValue}%)` }],
		{
			duration: duration * 1000 * 0.5,
			easing: "ease-out"
		}
	)

	animation.onfinish = () => {
		$root.style.transform = `translateY(-${yValue}%)`
		resolve?.()
	}
}
