import debug from "@cher-ami/debug"
import { Component } from "~/libs/compose"
import { Timeline, Interpol } from "@wbe/interpol"
import Vec3 from "~/libs/vec3"
import wait from "~/helpers/wait"

const componentName = "IntroCards"
const log = debug(`front:${componentName}`)

type TStaticProps = {}

/**
 * @name IntroCards
 */
export default class IntroCards extends Component<TStaticProps> {
	static attrName = "IntroCards"

	public elements = {
		$cardItems: this.findAll("item")
	}
	loopCardsIdleIsRunning: any
	loopCardsIdleTls: Timeline[] = []

	// ----------------------------------------------------------------------------- LIFECYCLE

	mounted() {
		// init anim
		log("> mounted")
		this.prepare()

		// this.playIn()
	}

	unmounted() {
		// remove inline css of
		this.elements.$cardItems.forEach((card) => {
			// hide cards
			card.style.opacity = "0"
		})
		// remove all tweens from the loop
		this.loopCardsIdleTls.forEach((tl) => {
			tl.stop()
		})
		this.loopCardsIdleTls = []
	}

	// ----------------------------------------------------------------------------- ANIMATION

	private prepare(): void {}

	override playIn(delay?): void {
		delay = delay || 0
		const defaultDuration = 300

		const [backCard, frontCard] = this.elements.$cardItems
		const frontCardProps = this.getTransformProps(frontCard)
		const backCardProps = this.getTransformProps(backCard)
		// reassign
		frontCardProps.z = 0
		backCardProps.z = -30

		this.convertPixelsToPercentage(backCardProps, backCard.clientWidth)
		this.convertPixelsToPercentage(frontCardProps, frontCard.clientWidth)

		const tlCardsReveal = this.animateCardsReveal(
			[frontCard, backCard],
			[frontCardProps, backCardProps],
			0
		)

		// const tlFrontCardSwitch = this.animateCardSwitch(
		// 	frontCard,
		// 	frontCardProps,
		// 	backCardProps,
		// 	new Vec3(-25, 80, -80),
		// 	10,
		// 	defaultDuration
		// )

		// const tlBackCardSwitch = this.animateCardSwitch(
		// 	backCard,
		// 	backCardProps,
		// 	frontCardProps,
		// 	new Vec3(25, -40, 0),
		// 	22,
		// 	defaultDuration
		// )

		;(async () => {
			await tlCardsReveal.play()
			// await for both animations to finish
			// await Promise.all([tlFrontCardSwitch.play(), tlBackCardSwitch.play()])
			this.loopCardsIdle(this.elements.$cardItems)
		})()
	}

	private animateCardsReveal(cards, cardProps, delay): Timeline {
		const tl = new Timeline({ paused: true })
		cards.forEach((card, i) => {
			tl.add(
				{
					el: card,
					props: {
						opacity: 1
					},
					duration: 100
				},
				delay + i * 300
			).add(
				{
					el: card,
					props: {
						scale: [0, 1],
						rotate: [cardProps[i].rotation + 44, cardProps[i].rotation, "deg"],
						x: [cardProps[i].x, cardProps[i].x, "%"],
						y: [cardProps[i].y, cardProps[i].y, "%"],
						z: [-20000, cardProps[i].z, "rem"]
					},
					duration: 700,
					ease: "power4.out"
				},
				delay + i * 300
			)
		})
		return tl
	}

	private animateCardSwitch(
		card: HTMLElement,
		baseProps: any,
		destProps: any,
		intermediatePos: Vec3,
		intermediateRot: number,
		duration: number
	): Timeline {
		const timeline = new Timeline({ paused: true })

		timeline
			.add(
				{
					el: card,
					props: {
						x: [baseProps.x, intermediatePos.x, "%"],
						y: [baseProps.y, intermediatePos.y, "%"],
						z: [baseProps.z, intermediatePos.z, "rem"],
						rotate: [baseProps.rotation, intermediateRot, "deg"]
					},
					duration,
					ease: "power2.out"
				},
				"+=200"
			)
			.add({
				el: card,
				props: {
					x: [intermediatePos.x, destProps.x, "%"],
					y: [intermediatePos.y, destProps.y, "%"],
					z: [intermediatePos.z, destProps.z, "rem"],
					rotate: [intermediateRot, destProps.rotation, "deg"]
				},
				duration: duration * 1.3,
				ease: "power4.out"
			})

		return timeline
	}

	private loopCardsIdle(cards): void {
		if (this.loopCardsIdleTls.length) return
		cards.forEach(async (card, i) => {
			card.classList.add("idle-state")
			const cardProps = this.getTransformProps(card)
			this.convertPixelsToPercentage(cardProps, card.clientWidth)
			let { y, rotation } = cardProps
			// FIXME: temp workaround for false y get from second card
			if (i === 1) {
				// y -= 10
			}
			if (i === 0) {
				y -= 10
			}
			wait(300 * i)
			const tl = this.animateCardIdle(card, rotation, y)
			const repeat = async () => {
				await tl.play()
				repeat()
			}
			repeat()
			this.loopCardsIdleTls.push(tl)
		})
		this.loopCardsIdleIsRunning = false
	}

	private animateCardIdle(card: HTMLElement, rotation, y): Timeline {
		const random = (min, max) => Math.random() * (max - min) + min

		const firstRotation = rotation + random(-3, 3)
		const firstY = y + random(-3, 10)
		const tl = new Timeline({ paused: true })
		tl.add({
			el: card,
			props: {
				y: [y, firstY, "%"],
				rotate: [rotation, firstRotation, "deg"]
			},
			duration: random(1100, 1500),
			ease: "power1.out"
		}).add({
			el: card,
			props: {
				y: [firstY, y, "%"],
				rotate: [firstRotation, rotation, "deg"]
			},
			duration: random(1100, 1500),
			ease: "linear"
		})
		return tl
	}

	// ----------------------------------------------------------------------------- HELPERS

	private getTransformProps(element: HTMLElement) {
		const style = window.getComputedStyle(element)
		const matrix = new DOMMatrixReadOnly(style.transform)
		return {
			x: matrix.m41,
			y: matrix.m42,
			z: matrix.m43,
			rotation: Math.acos(matrix.a) * (180 / Math.PI)
		}
	}

	private convertPixelsToPercentage(props: any, clientWidth: number): void {
		;["x", "y"].forEach((axis) => {
			props[axis] = (props[axis] / clientWidth) * 100
		})
	}
}
