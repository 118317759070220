import mitt, { Emitter } from "mitt"

export type PostsNavigationServiceEvents = {
	postchange: number
}

class PostsNavigationService {
	public emitter: Emitter<PostsNavigationServiceEvents> =
		mitt<PostsNavigationServiceEvents>()

	public _currentIndex: number = 0
	get currentIndex(): number {
		return this._currentIndex
	}
	set currentIndex(value: number) {
		this._currentIndex = value
	}

	public _totalPosts: number = 0
	get totalPosts(): number {
		return this._totalPosts
	}
	set totalPosts(value: number) {
		this._totalPosts = value
	}

	/**
	 * Update currentPage name
	 */
	public updateCurrentPost(index: number): void {
		this.emitter.emit("postchange", index)
		this._currentIndex = index
	}

	public on = (
		event: keyof PostsNavigationServiceEvents,
		callback: (index: number) => void
	) => {
		this.emitter.on(event, callback)
	}

	public off = (
		event: keyof PostsNavigationServiceEvents,
		callback: (index: number) => void
	) => {
		this.emitter.off(event, callback)
	}
}

const postsNavigationService = new PostsNavigationService()
export default postsNavigationService
