import debug from "@cher-ami/debug"
import App from "./components/app/App"
import { createBrowserHistory } from "history"
import "focus-visible"
;(async () => {
	/**
	 * Logs
	 */
	const log = debug("front:index")
	log("meta:", import.meta)

	// start App when data are loaded
	new App({
		$root: document.querySelector<HTMLElement>(".App"),
		history: createBrowserHistory(),
		props: {}
	})
})()
