import debug from "@cher-ami/debug"
import gsap from "gsap"
import addTooltip from "~/helpers/addTooltipSvg"
import { isMobile } from "~/helpers/isMobile"
import { Component } from "~/libs/compose"
import { MemberVote, Party, PartyName, PartyTotals, VoteType, Votes } from "~/types/votes"

const componentName = "PostVoteDataViz"
const log = debug(`front:${componentName}`)

type TStaticProps = {}

/**
 * @name PostVoteDataViz
 */
export default class PostVoteDataViz extends Component<TStaticProps> {
	static attrName = componentName

	public elements = {
		$inner: this.find<HTMLDivElement>("inner"),
		$vizContainer: this.find<HTMLDivElement>("viz-container")
	}
	public sourceId: string

	// ----------------------------------------------------------------------------- LIFECYCLE

	mounted() {
		log("> mounted")

		this.sourceId = this.$root.dataset.sourceId
		// if svg in post-vote-dataviz__viz-container present, do not init the viz
		if (!this.elements.$vizContainer.querySelector("svg")) {
			this.initViz()
		}

		// TODO: playInViz trigger directly
		//
	}

	unmounted() {
		this.detachEvents()
	}

	// ----------------------------------------------------------------------------- SETUP

	async initViz() {
		const data = (await this.fetchData(this.sourceId)) as Votes
		const partyTotals = (await this.fetchData("all-vote-groups")) as PartyTotals
		const svg = this.createSvg(data, partyTotals)
		// @ts-ignore
		this.elements.$vizContainer.appendChild(svg)
		addTooltip(svg, "circle")
		this.attachEvents()
		if (!isMobile()) {
			this.showVizOnScroll()
		}
	}

	// fetch json helper method to get data from public/data/
	async fetchData(fileName: string) {
		const baseUrl = "/data/vote-data/"
		try {
			const response = await fetch(baseUrl + fileName + ".json")
			if (!response.ok) {
				throw new Error("Network response was not ok")
			}
			return await response.json()
		} catch (error) {
			console.error("There has been a problem while fetching data: ", error)
		}
	}

	// TODO: refactor
	createSvg(data: Votes, partyTotals: PartyTotals) {
		const voteData = data

		// get an array of object that represent all participants with a vote type and party affiliation
		const participants = this.getParticipantsData(voteData)

		// get total votes method
		const totalVotes = this.getTotalVotes(voteData)

		// filter out the absent parties from the data
		const votingParties = this.getVotingParties(partyTotals, participants)

		const svg = this.createSvgElement("svg", {
			viewBox: `0 0 782 406`,
			preserveAspectRatio: "xMinYMin meet",
			width: "100%",
			height: "100%"
		})

		const radius = 20
		const totalSeats = participants.length
		if (totalVotes == 0 || totalSeats == 0) return "No data to display"

		// create points array
		let points = this.createPoints(totalSeats, radius, votingParties, participants)

		// create svg
		const A: number = points[0][2] / 0.1
		const viewBoxValues = {
			x: -radius - A / 2,
			y: -radius - A / 2,
			width: 2 * radius + A,
			height: radius + A
		}
		svg.setAttribute(
			"viewBox",
			`${viewBoxValues.x} ${viewBoxValues.y} ${viewBoxValues.width} ${viewBoxValues.height}`
		)

		// Create circle group
		const shapesGroup = this.createSvgElement("g", {})

		// Create circles
		points.forEach((d) => {
			// TODO: Logic to check if filters apply
			const participant = participants[d[5]]
			const vote = participant.vote
			const opacity = vote === "no" ? 0.6 : 1

			// Always create circle shape even if vote is "no" (hidden in that case) to facilitate hover and tooltip positioning
			const circleShape = this.createSvgElement("circle", {
				cx: d[0],
				cy: d[1],
				r: d[2],
				fill: d[3],
				class: vote === "no" ? `vote-${vote}` : "",
				// Other attributes based on conditions
				...(vote === "abstain"
					? { stroke: d[3], "stroke-width": "0.1", fill: "transparent" }
					: {}),
				style: `opacity: ${vote === "no" ? 0 : opacity}`
			})

			// Create cross shape for "no" votes
			let crossShape
			if (vote === "no") {
				const xDirection = Math.sign(+d[0])
				// xOffset is used to adjust the position of the cross -1 is left, 1 is right
				const xOffset = xDirection === -1 ? -0.97 : -1.03

				// Create cross for "no" votes
				crossShape = this.createSvgElement("path", {
					d: "M1.331.599 1.106.373 1.33.149a.429.429 0 0 0-.106-.106L1 .267.776.044A.409.409 0 0 0 .67.15l.224.224-.225.225a.409.409 0 0 0 .106.106L1 .48l.226.226A.404.404 0 0 0 1.331.6Z",
					fill: d[3],
					transform: `translate(${+d[0] + xOffset}, ${d[1] - 0.33}) scale(${d[2] * 2.5})`, // Adjust translation and scale as needed
					style: `opacity: ${opacity}`
				})
			}

			// Create title
			const title = this.createSvgElement("title", {})
			const voteName = vote === "no" ? "Contre" : vote === "yes" ? "Pour" : "Abstention"
			title.textContent = `${participant.name} (${d[4]}), vote : ${voteName}`

			// Append title to circle
			circleShape.appendChild(title)
			// Append circle to group
			shapesGroup.appendChild(circleShape)
			// Append cross to group
			if (crossShape) shapesGroup.appendChild(crossShape)
		})

		// Append globale shapes group to SVG
		svg.appendChild(shapesGroup)

		return svg
	}

	// ----------------------------------------------------------------------------- EVENTS

	attachEvents() {
		//this.elements.$buttonNext.addEventListener("click", this.next.bind(this))
		// this.elements.$buttonPrev.addEventListener("click", this.prev.bind(this))
	}

	detachEvents() {
		// this.elements.$buttonNext.removeEventListener("click", this.next.bind(this))
		// this.elements.$buttonPrev.removeEventListener("click", this.prev.bind(this))
	}

	// ----------------------------------------------------------------------------- SCROLL

	private showVizOnScroll() {
		const plainVizTween = this.createPlayInVizAnim(0)
		plainVizTween.pause()
		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						plainVizTween.play()
						observer.disconnect()
					}
				})
			},
			{ threshold: 0.5 }
		)
		observer.observe(this.elements.$vizContainer)
	}

	// ----------------------------------------------------------------------------- ANIMATION

	override playIn(delay): void {}

	createPlayInVizAnim(delay: number) {
		return gsap
			.from(this.elements.$vizContainer.querySelectorAll("circle:not(.vote-no), path"), {
				duration: 0.8,
				opacity: 0,
				ease: "power3.out",
				delay: delay,
				stagger: 0.0011,
				immediateRender: true,
				lazy: false,
				paused: true,
				onStart: () => {
					log("Viz animation started")
				}
			})
			.pause()
	}

	// ----------------------------------------------------------------------------- DATA MANIPULATION

	getParticipantsData(data: Votes): Array<MemberVote> {
		// get an array of object that represent all participants with a vote type and party affiliation
		const participants = this.createFlattenedVoteArray(data)
		// shuffle the participants array
		return participants.sort(() => Math.random() - 0.5)
	}

	createFlattenedVoteArray(voteData: Votes): MemberVote[] {
		const flattenedArray: MemberVote[] = []

		Object.entries(voteData).forEach(([vote, parties]) => {
			Object.entries(parties).forEach(([party, members]: [string, string[]]) => {
				if (!members || members?.length === 0 || Object.values(members)?.length === 0)
					return
				members?.forEach((memberName) => {
					flattenedArray.push({
						name: memberName,
						party: party as PartyName,
						vote: vote as VoteType
					})
				})
			})
		})

		return flattenedArray
	}

	private getVotingParties(partyTotals: PartyTotals, participants: MemberVote[]) {
		const partyVoting = partyTotals.filter((party) =>
			participants.some((participant) => participant.party == party.short)
		)
		// get the total number of participants per party and store it in the partyVoting object
		partyVoting.forEach((party) => {
			party.value = participants.filter(
				(participant) => participant.party == party.short
			).length
		})
		return partyVoting
	}

	private getTotalVotes(voteData: Votes) {
		return Object.values(voteData).reduce(
			(acc: number, group) =>
				acc +
				(Object.values(group).reduce(
					(acc: number, members: []) => acc + members.length,
					0
				) as number),
			0
		)
	}

	// -----------------------------------------------------------------------------  UTILS

	private createPoints(
		totalSeats: number,
		radius: number,
		partyVoting: Party[],
		participants: MemberVote[]
	) {
		const numberOfRings = this.findN(totalSeats, radius)
		const a0 = this.findA(totalSeats, numberOfRings, radius) // calculate seat distance

		let points = []

		// calculate ring radius
		var rings = []
		for (var i = 1; i <= numberOfRings; i++) {
			rings[i] = radius - (i - 1) * a0
		}
		// calculate seats per ring
		// @ts-ignore
		rings = this.distribute(rings, totalSeats)

		var r, a, point

		// build seats
		// loop rings
		var ring
		for (var j = 1; j <= numberOfRings; j++) {
			ring = []
			// calculate ring-specific radius
			r = radius - (j - 1) * a0
			// calculate ring-specific distance
			a = (Math.PI * r) / (rings[j] - 1 || 1)

			// loop points
			for (let k = 0; k <= rings[j] - 1; k++) {
				point = this.getCoordinates(r, k * a)
				point[2] = 0.4 * a0
				ring.push(point)
			}
			points.push(ring)
		}

		// fill seats
		let ringProgress = Array(points.length).fill(0)

		for (let party in partyVoting) {
			let short = partyVoting[party].short
			// @ts-ignore
			for (let l = 0; l < parseInt(partyVoting[party].value); l++) {
				ring = this.nextRing(points, ringProgress)
				points[ring][ringProgress[ring]][3] = partyVoting[party].rgb

				points[ring][ringProgress[ring]][4] = short
				ringProgress[ring]++
			}
		}
		points = this.merge(points)

		// add code to store the MPs' name, QID URL and gender
		for (let i = 0; i < participants.length; i++) {
			for (let j = 0; j < points.length; j++) {
				if (participants[i].party == points[j][4] && points[j].length == 5) {
					points[j][5] = i // store the index of the data
					break
				}
			}
		}
		return points
	}

	private createSvgElement(elementName: string, attributes: { [key: string]: any }) {
		const svgNS = "http://www.w3.org/2000/svg"
		const element = document.createElementNS(svgNS, elementName)
		for (const attr in attributes) {
			element.setAttribute(attr, attributes[attr])
		}
		return element
	}

	// ----------------------------------------------------------------------------- DRAW SVG MATH UTILS

	findN(m, r) {
		var n = Math.floor(Math.log(m) / Math.log(2)) || 1
		var distance = this.getScore(m, n, r)

		var direction = 0
		if (this.getScore(m, n + 1, r) < distance) direction = 1
		if (this.getScore(m, n - 1, r) < distance && n > 1) direction = -1

		while (this.getScore(m, n + direction, r) < distance && n > 0) {
			distance = this.getScore(m, n + direction, r)
			n += direction
		}
		return n
	}
	findA(m, n, r) {
		var x = (Math.PI * n * r) / (m - n)
		var y = 1 + (Math.PI * (n - 1) * n) / 2 / (m - n)

		var a = x / y
		return a
	}
	getScore(m, n, r) {
		return Math.abs((this.findA(m, n, r) * n) / r - 5 / 7)
	}
	distribute(votes, seats) {
		// initial settings for divisor finding
		var voteSum = 0
		for (var party in votes) {
			voteSum += votes[party]
		}
		var low = voteSum / (seats - 2)
		var high = voteSum / (seats + 2)
		var divisor = voteSum / seats

		var parliament = this.calculateSeats(votes, divisor)

		// find divisor
		while (parliament.seats != seats) {
			if (parliament.seats < seats) low = divisor
			if (parliament.seats > seats) high = divisor
			divisor = (low + high) / 2
			parliament = this.calculateSeats(votes, divisor)
		}

		return parliament.distribution
	}
	calculateSeats(votes, divisor) {
		var distribution = {}
		var seats = 0
		for (var party in votes) {
			distribution[party] = Math.round(votes[party] / divisor)
			seats += distribution[party]
		}
		return { distribution, seats }
	}

	getCoordinates(r: number, b: number) {
		// @ts-ignore
		var x = parseFloat(r * Math.cos(b / r - Math.PI)).toFixed(10)
		// @ts-ignore
		var y = parseFloat(r * Math.sin(b / r - Math.PI)).toFixed(10)
		return [x, y]
	}

	nextRing(rings, ringProgress) {
		var progressQuota, tQuota
		for (var i in rings) {
			// @ts-ignore
			tQuota = parseFloat((ringProgress[i] || 0) / rings[i].length).toFixed(10)
			if (!progressQuota || tQuota < progressQuota) progressQuota = tQuota
		}
		for (var j in rings) {
			// @ts-ignore
			tQuota = parseFloat((ringProgress[j] || 0) / rings[j].length).toFixed(10)
			if (tQuota == progressQuota) return j
		}
	}

	merge(arrays) {
		var result = []
		for (var list of arrays) result = result.concat(list)
		return result
	}
}
