import debug from "@cher-ami/debug"
import { Component } from "~/libs/compose"
import { Interpol, Timeline } from "@wbe/interpol"
import splitTextLines from "~/helpers/splitTextLines"
import IntroStars from "../introStars/IntroStars"
import IntroCards from "../introCards/IntroCards"
import wait from "~/helpers/wait"
import { isMobile } from "~/helpers/isMobile"

const componentName = "Intro"
const log = debug(`front:${componentName}`)

type TStaticProps = {}

/**
 * @name Intro
 */
export default class Intro extends Component<TStaticProps> {
	static attrName = "Intro"

	public introStars = this.add(IntroStars)
	public introCards = this.add(IntroCards)

	public prepared: boolean = false
	originalTexts: any[]

	public elements = {
		$container: this.find<HTMLElement>("container"),
		$title: this.find<HTMLElement>("title"),
		$text: this.find<HTMLElement>("text").querySelector("p"),
		$discoverLink: this.find<HTMLElement>("link-discover"),
		$discoverLinkIcon: this.find<HTMLElement>("icon-discover")
	}
	originalTextsHtml: any

	// ----------------------------------------------------------------------------- LIFECYCLE

	mounted() {
		// init anim
		log("> mounted")

		this.prepare()
		// this.addEvents()
	}

	unmounted() {
		log("> unmounted")
		// assign back the original text to the title and text elements
		this.elements.$title.innerHTML = this.originalTextsHtml[0]
		if (!isMobile()) this.elements.$text.innerHTML = this.originalTextsHtml[1]

		// reset this.elements.$discoverLink and this.elements.$discoverLinkIcon inline styles
		this.elements.$discoverLink.style.transform = ""
		this.elements.$discoverLinkIcon.style.transform = ""
	}

	prepare() {
		if (this.prepared) return

		this.prepareText()

		this.prepared = true
	}

	// ----------------------------------------------------------------------------- EVENTS / METHODS

	// ----------------------------------------------------------------------------- ANIMATION

	override async playIn(delay?) {
		delay = delay || 0

		this.animateTextLines(delay)
		this.introCards.playIn(delay)
		await wait(400)
		this.introStars.playIn(delay)
		await wait(500)
		this.animateDiscoverLink()
	}

	animateDiscoverLink() {
		const tl = new Timeline()
		tl.add(
			{
				el: this.elements.$discoverLink,
				props: {
					y: [102, 0, "%"]
				},
				duration: 400,
				ease: "power2.out"
			},
			0
		).add(
			{
				el: this.elements.$discoverLinkIcon,
				props: {
					y: [-102, 0, "%"]
				},
				duration: 400,
				ease: "power2.out"
			},
			0
		)
	}

	private prepareText(): void {
		const texts = [this.elements.$title, !isMobile() && this.elements.$text]
		this.originalTextsHtml = texts.map((el) => el.innerHTML)
		texts.map((el) => splitTextLines(el))
	}

	private async animateTextLines(delay: number = 0) {
		const tl = new Timeline()
		this.fadeInElement(this.elements.$title)
		this.fadeInElement(this.elements.$text, isMobile() ? 700 : 0)

		this.textLinesAnimation(this.elements.$title, delay, 700, 150, [102, 0, "%"])
		const textStartDelay = this.elements.$title.querySelectorAll(".line").length * 150
		this.textLinesAnimation(
			this.elements.$text,
			delay + textStartDelay,
			500,
			100,
			[80, 0, "%"],
			true
		)
	}

	private async fadeInElement(element: HTMLElement, delay: number = 0): Promise<void> {
		await wait(delay)

		// animate opacity from 0 to 1 with Interpol
		new Interpol({
			// can recieve HTMLElement or HTMLElement[]
			el: element,
			duration: 300,
			props: {
				opacity: [0, 1]
			}
		})
	}

	private textLinesAnimation(
		parentElement: HTMLElement,
		initialDelay: number,
		duration: number,
		stagger: number,
		yProps: [number, number, string],
		includeOpacity: boolean = false
	): Timeline {
		const timeline = new Timeline()
		const lines: HTMLElement[] = Array.from(parentElement.querySelectorAll(".line"))
		lines.forEach((line, index) => {
			const props = {
				y: yProps
			}

			if (includeOpacity) {
				props["opacity"] = 1
			}

			timeline.add(
				{
					el: line,
					props: props,
					duration: duration,
					ease: "power2.out"
				},
				initialDelay + index * stagger
			)
		})
		return timeline
	}
}
