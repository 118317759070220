import debug from "@cher-ami/debug"
import { Component } from "~/libs/compose"
import { Timeline, Interpol } from "@wbe/interpol"

const componentName = "IntroStars"
const log = debug(`front:${componentName}`)

type TStaticProps = {}

/**
 * @name IntroStars
 */
export default class IntroStars extends Component<TStaticProps> {
	static attrName = "IntroStars"

	public elements = {
		$starItems: this.findAll("item"),
		$starIcons: this.$root.querySelectorAll<SVGElement>(".icon--star")
	}
	loopCircleTweens: Interpol<string>[] = []

	// ----------------------------------------------------------------------------- LIFECYCLE

	mounted() {
		// init anim
		log("> mounted")
		this.prepare()
	}

	public unmounted(): void {
		log("> unmounted")
		this.setupStarsInitialPosition()
		// hide the stars
		this.elements.$starIcons.forEach((el) => {
			el.style.transform = "scale(0)"
		})
		this.loopCircleTweens.forEach((tween) => {
			tween.refreshComputedValues()
			tween.stop()
		})
		// remove all tweens from the loop
		this.loopCircleTweens = []
	}

	// ----------------------------------------------------------------------------- SETUP

	private prepare(): void {
		this.setupStarsInitialPosition()
	}

	private setupStarsInitialPosition(): void {
		const radius = this.getStarsRadius()

		// Calculate the angle at which the star should be positioned & set the transform
		this.elements.$starItems.forEach((star, i) => {
			const angle = (i / this.elements.$starItems.length) * Math.PI * 2 - Math.PI / 2
			const position = this.getStarCirlePosition(angle, radius)
			// set transform
			star.style.transform = `translate3D(${position.x}rem, ${position.y}rem, 0)`
		})
	}

	// ----------------------------------------------------------------------------- ANIMATION

	override playIn(delay?): void {
		delay = delay || 0
		// Create a timeline instance
		const tl = new Timeline()
		log("> playIn")

		// Animate the stars reveal scale and rotation
		this.elements.$starIcons.forEach((el, i) => {
			tl.add(
				{
					// @ts-ignore
					el,
					props: {
						scale: [0, 1],
						rotate: [-45, 0, "deg"]
					},
					duration: 700,
					ease: "power2.out"
				},
				delay + i * 90
			)
		})

		// Animate the stars position on the circle in a loop
		if (this.loopCircleTweens && !this.loopCircleTweens.length) {
			this.animateStarsCircleLoop(delay)
		}
	}

	private animateStarsCircleLoop(delay: any) {
		this.elements.$starItems.forEach((el, i) => {
			const startAngle = (i / this.elements.$starItems.length) * Math.PI * 2 - Math.PI / 2
			const endAngle = startAngle + Math.PI * 2
			const radius = this.getStarsRadius()

			const tween = new Interpol({
				paused: true,
				props: {
					angle: [startAngle, endAngle]
				},
				duration: 8000,
				onUpdate: (props) => {
					const position = this.getStarCirlePosition(props.angle, radius)
					el.style.transform = `translate3D(${position.x}rem, ${position.y}rem, 0)`
				},
				onComplete: () => {}
			})
			const repeat = () => {
				tween.refreshComputedValues()
				tween.play().then(repeat)
			}
			setTimeout(() => {
				repeat()
			}, delay)

			this.loopCircleTweens.push(tween)
		})
	}

	// ----------------------------------------------------------------------------- HELPERS

	// Function to calculate the position on the circle
	private getStarCirlePosition = (angle, radius) => {
		return {
			x: Math.cos(angle) * radius,
			y: Math.sin(angle) * radius
		}
	}

	private getStarsRadius = () => {
		return this.$root.clientWidth / 2
	}
}
