function addTooltip(containerElement, elementSelector = null) {
	const mouseOffset = [0.5, 0.5]

	const style = document.createElement("style")
	style.textContent = `
      .svg-tooltip {
		font-size: 1.2px;
		line-height: 1.2px;
		text-align: center;
		color: #000;
		background-color: #fff;
		position: absolute;
		top: 0;
		left: 0;
		max-width: 15px;
    	min-height: 5px;
		min-width: 10px;
		transform: translate(178px, 410.19px);
		padding: 0.8px;
		visibility: hidden;
		border-radius: 0.5rem;
    	box-shadow: 0 0 0px 0.1px black;
      }
	  .svg-tooltip > div {
		margin-top: 0.6px;
	  }
	  `

	// Append style to the head
	document.head.appendChild(style)

	const foreignObject = document.createElementNS(
		"http://www.w3.org/2000/svg",
		"foreignObject"
	)
	// get containerElement bbox
	const bbox = containerElement.getBBox()

	foreignObject.setAttribute("width", "100%")
	foreignObject.setAttribute("height", "100%")
	foreignObject.style.transform = "translate(-50%, -100%)"
	foreignObject.style.pointerEvents = "none"

	containerElement.appendChild(foreignObject)

	const tooltipDiv = document.createElement("div")
	tooltipDiv.setAttribute("class", "svg-tooltip")
	foreignObject.appendChild(tooltipDiv)
	// containerElement.appendChild(tooltipDiv)

	function show(text, x, y) {
		let posX = x + mouseOffset[0]
		let posY = y + mouseOffset[1]

		// create html from text by splitting in two div elements from ", " separator
		const textArray = text.split(", ")
		// create divs for each element
		const toolTipContentHtml = textArray.map((el) => `<div>${el}</div>`).join("")

		tooltipDiv.innerHTML = toolTipContentHtml
		tooltipDiv.style.visibility = "visible"

		// const svgBox = containerElement.getBoundingClientRect()
		const tooltipBox = tooltipDiv.getBoundingClientRect()

		// get svg bbox
		const svgBBox = containerElement.getBBox()
		const svgBox = containerElement.getBoundingClientRect()
		// get width ratio
		const ratio = {
			width: svgBox.width / svgBBox.width,
			height: svgBox.height / svgBBox.height
		}

		const tooltipWidth = tooltipBox.width / ratio.width
		const tooltipHeight = tooltipBox.height / ratio.height

		if (posX > svgBBox.width - tooltipWidth - 23) {
			posX = x - tooltipWidth - mouseOffset[0]
		}
		if (posY > svgBBox.height - tooltipHeight - 25) {
			posY = y - tooltipHeight - mouseOffset[1]
		}

		// offset because position 0, 0 is at bottom middle
		posY += svgBBox.height
		posX += svgBBox.width / 2

		tooltipDiv.style.transform = `translate(${posX}px, ${posY}px)`
	}

	function hide() {
		tooltipDiv.style.visibility = "hidden"
	}

	if (elementSelector != null) {
		const elements = containerElement.querySelectorAll(elementSelector)
		elements.forEach((element) => {
			element.addEventListener("mouseover", (e) => {
				const title = e.target.querySelector("title")
					? e.target.querySelector("title").textContent
					: ""
				const bbox = e.target.getBBox()
				const centroid = [bbox.x + bbox.width / 2, bbox.y + bbox.height / 2]
				show(title, centroid[0], centroid[1])
			})

			element.addEventListener("mouseleave", () => hide())
		})
	}

	return { show, hide }
}
export default addTooltip
