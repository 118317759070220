export enum ERafExecuteOrder {
	LENIS = 0,
	GL_GALLERY_SCENE = 10,
	GL_PLANE_SCENE = 10,
	GL_RENDER = 100
}

export interface IListenerItem {
	function: (e: IRafEvent) => void
	executeOrder?: ERafExecuteOrder
	name?: string
}

export interface IRafEvent {
	elapsedTime: number
	deltaTime: number
	time: number
}
/**
 * @name RafService
 * @description
 * RafService is a Service that allow gathering all the RAF in one place and decide the order of execution
 * It our case it is really hand to execute every service RAF in the proper order
 * For example we want to execute the Lenis RAF before everything, then the GL scenes RAFs and finally the GL render raf
 * so that everything is update BEFORE we make the WebGL render.
 */
class RafService {
	raf: number = 0
	listenersObj: Array<IListenerItem> = []
	time: number
	keepElapsedTime: number = 0
	startTime: number
	deltaTime: number
	elapsedTime: number
	isRunning: boolean = false

	/**
	 * @name start
	 * @description Start the RAF loop
	 */
	start() {
		if (this.isRunning) return
		this.isRunning = true
		this.startTime = performance.now()
		this.time = this.startTime
		this.elapsedTime = this.keepElapsedTime + (this.time - this.startTime)
		this.deltaTime = 16
		this.raf = requestAnimationFrame(this.tick)
	}

	/**
	 * @name stop
	 * @description Stop the RAF loop and reset the elapsed time
	 */
	stop() {
		if (!this.isRunning) return
		this.isRunning = false
		this.keepElapsedTime = 0
		this.elapsedTime = 0
		cancelAnimationFrame(this.raf)
	}

	/**
	 * @name pause
	 * @description Pause the RAF loop and keep the elapsed in memory to avoid clip on (re)start
	 */
	pause() {
		if (!this.isRunning) return
		this.isRunning = false
		cancelAnimationFrame(this.raf)
		this.keepElapsedTime = this.elapsedTime
	}

	/**
	 * @name addListener
	 * @param  {
	 *  function: (e: IRafEvent) => void
	 *  executeOrder?: ERafExecuteOrder
	 *  name?: string
	 * } e
	 * @description Add a function that run on every RAF and decide the order of execution
	 */
	addListener(e: IListenerItem) {
		this.listenersObj.push(e)
		this.listenersObj.sort((a, b) => {
			return a.executeOrder - b.executeOrder
		})
	}

	/**
	 * @name removeListener
	 * @param {Function} listenerFunction
	 * @description Remove a function from the RAF loop
	 */
	removeListener(listenerFunction: Function) {
		this.listenersObj = this.listenersObj.filter((obj) => {
			return obj.function !== listenerFunction
		})
	}

	/**
	 * @name tick
	 * @param {number} time
	 * @description Function that run on every frame using RequestAniamtionFrame method
	 */
	tick = (time: number) => {
		const now = performance.now()
		this.deltaTime = now - this.time
		this.time = now
		this.elapsedTime = this.keepElapsedTime + (this.time - this.startTime)
		this.listenersObj.forEach((obj) => {
			obj.function({
				elapsedTime: this.elapsedTime,
				deltaTime: this.deltaTime,
				time: this.time
			})
		})
		this.raf = requestAnimationFrame(this.tick)
	}
}

export default new RafService()
