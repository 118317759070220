import {
	defaultPlayIn,
	defaultPlayInTranslate,
	defaultPlayOut
} from "~/helpers/defaultTransitions"
import debug from "@cher-ami/debug"
import { Component } from "~/libs/compose"
import PostSlider from "~/components/postSlider/PostSlider"
import gsap from "gsap"
import PostHand from "~/components/postHand/postHand"
import PostNavigationStars from "~/components/postNavigationStars/PostNavigationStars"
import postsNavigationService from "~/services/PostsNavigationService"
import PostSliderButton from "~/components/postSliderButton/PostSliderButton"

const log = debug(`front:SinglePostPage`)

type TStaticProps = {}

/**
 * @name SinglePostPage
 */
export default class SinglePostPage extends Component<TStaticProps> {
	public static attrName = "SinglePostPage"

	public slider = this.add(PostSlider)
	public hand = this.add(PostHand)
	public nav = this.add(PostNavigationStars)
	public buttons = this.addAll(PostSliderButton) as PostSliderButton[]

	private isPostTransiting: boolean = false

	public mounted() {
		log("> mounted")
		log("this.slider", this.slider)
		window.addEventListener("resize", this.resizeHandler)
		this.slider.hand = this.hand

		this.attachEvents()

		// NOTE : Mandatory to use post navigation service
		// set total posts and current index
		postsNavigationService.totalPosts = this.slider.slides.length
		postsNavigationService.currentIndex = this.slider.currentSlideIndex
	}

	public unmounted() {
		window.removeEventListener("resize", this.resizeHandler)
		this.slider.unmounted()

		this.detachEvents()
	}

	protected resizeHandler = () => {}

	// ----------------------------------------------------------------------------- EVENTS

	attachEvents() {
		postsNavigationService.on("postchange", this.onPostChange)
		this.nav.emitter.on("nav:change", this.handleNavChange)
		this.buttons.forEach((button) => button.emitter.on("click", this.onSliderButtonClick))
	}

	detachEvents() {
		this.nav.emitter.off("nav:change", this.handleNavChange)
		postsNavigationService.off("postchange", this.onPostChange)
		this.buttons.forEach((button) =>
			button.emitter.off("click", this.onSliderButtonClick)
		)
	}

	// --------------------------------------------------------------------------- CONTROL SLIDER

	private onPostChange = async (index: number) => {
		if (this.isPostTransiting) return
		this.isPostTransiting = true
		this.nav.update(index)
		await this.slider.goTo(index)
		this.isPostTransiting = false
	}

	private handleNavChange = ({ index }: { index: number }) => {
		postsNavigationService.updateCurrentPost(index)
	}

	private onSliderButtonClick = ({
		event,
		direction
	}: {
		event: Event
		direction: number
	}) => {
		if (this.isPostTransiting) return
		// get target index in loop from direction
		let targetIndex
		const currentIndex = postsNavigationService.currentIndex
		const totalPosts = postsNavigationService.totalPosts
		targetIndex =
			direction === 1
				? (currentIndex + 1) % totalPosts
				: (currentIndex - 1 + totalPosts) % totalPosts

		postsNavigationService.updateCurrentPost(targetIndex)
	}

	// --------------------------------------------------------------------------- PAGE TRANSITION

	public playOut(goTo: string, resolve: () => void) {
		defaultPlayOut(this.$root, goTo, resolve)
	}

	public playIn(comeFrom: string, resolve: () => void) {
		log("> playIn", { buttons: this.buttons })
		// reveal slider
		this.slider.playIn(0)

		// reveal nav
		this.nav.playIn(1200)

		// reveal buttons
		this.buttons.forEach((button) => button.playIn(1.3))

		gsap.delayedCall(0.01, () => {
			this.$root.style.opacity = "1"
			defaultPlayInTranslate(this.$root, comeFrom, resolve)
		})
		gsap.delayedCall(0.4, () => {
			this.handleNavChange({ index: +this.slider.$root.dataset.startIndex })
		})
	}
}
