import {
	defaultPlayIn,
	defaultPlayOut,
	defaultPlayOutTranslate
} from "~/helpers/defaultTransitions"
import debug from "@cher-ami/debug"
import { Component } from "~/libs/compose"
import Intro from "~/components/intro/Intro"
import { isMobile } from "~/helpers/isMobile"
const log = debug(`front:HomePage`)

type TStaticProps = {}

/**
 * @name HomePage
 */
export default class HomePage extends Component<TStaticProps> {
	public static attrName = "HomePage"

	private touchStartY = 0
	private firstStoryUrl: string

	// ----------------------------------------------------------------------------- COMPONENTS

	public intro = this.add(Intro)
	canOpenFirstStory: boolean = false
	canOpenFirstStoryTimeout: null | ReturnType<typeof setTimeout> = null

	// ----------------------------------------------------------------------------- LIFECYCLE

	public mounted() {
		log("> mounted")

		this.firstStoryUrl = this.getFirstStoryUrl()
		this.attachEvents()

		// TODO: check if working with cache
		// prefetch next story with a delay
		setTimeout(() => {
			this.prefetchFirstStory(this.firstStoryUrl)
		}, 3000)
	}

	public unmounted() {
		this.detachEvents()
		this.resetPreScrollIntro()
	}

	// ----------------------------------------------------------------------------- EVENTS

	protected attachEvents() {
		// cross browser wheel event
		window.addEventListener("wheel", this.wheelHandler)
		window.addEventListener("mousewheel", this.wheelHandler)
		// mobile touchmove event
		window.addEventListener("touchstart", this.touchStartHandler)
		window.addEventListener("touchmove", this.wheelHandler)
	}

	protected detachEvents() {
		window.removeEventListener("wheel", this.wheelHandler)
		window.removeEventListener("mousewheel", this.wheelHandler)
		window.removeEventListener("touchmove", this.wheelHandler)

		this.resetPreScrollIntro()
	}

	protected touchStartHandler = (e: TouchEvent) => {
		this.touchStartY = e.touches[0].pageY
	}

	protected wheelHandler = (e: WheelEvent | TouchEvent) => {
		// open first story if deltaY > 8 and we are at the bottom of the page
		const scrollDelta = (e as WheelEvent)?.deltaY
		const touchDelta = this.touchStartY - (e as TouchEvent)?.touches?.[0]?.pageY
		const deltaY = scrollDelta || touchDelta
		const tresholdY = e.type === "touchmove" ? 14 : 10

		// if we are at the bottom of the page
		if (
			window.scrollY + window.innerHeight >= document.body.offsetHeight ||
			window.innerHeight + window.pageYOffset >= document.body.offsetHeight
		) {
			// allow to open first story only if we are at the bottom of the page and after a timeout
			this.canOpenFirstStoryTimeout = setTimeout(() => {
				this.canOpenFirstStory = true
			}, 100)

			// allow to open directly the first story on desktop
			if (!isMobile()) {
				this.canOpenFirstStory = true
			}

			// open first story if deltaY > tresholdY
			if (this.canOpenFirstStory) {
				if (deltaY > tresholdY) {
					this.openFirstStory(this.firstStoryUrl)
				}

				this.preScrollIntro(deltaY, tresholdY)
			}
		} else {
			this.canOpenFirstStory = false
			clearTimeout(this.canOpenFirstStoryTimeout)
		}
	}

	preScrollIntro(deltaY: number, treshold: number = 10) {
		this.intro.$root.style.transform = `translateY(${-deltaY / (isMobile() ? 1.5 : 1.75)}px)`

		setTimeout(() => {
			if (Math.abs(deltaY) < treshold * 1.2) {
				this.$root.style.transform = `translateY(0)`
			}
		}, 200)
	}

	resetPreScrollIntro() {
		this.intro.$root.style.transform = `translateY(0)`
	}

	// --------------------------------------------------------------------------- HISTORY CONTROL

	openFirstStory = (url: string) => {
		this.stack.handleHistory(url)
	}

	// prefetch next story for faster navigation
	prefetchFirstStory = async (url) => {
		await this.stack.fetchNewDocument(url, new AbortController())
	}

	getFirstStoryUrl = () => {
		const link = document.querySelector(".intro__link") as HTMLAnchorElement
		return link.href
	}

	// --------------------------------------------------------------------------- PAGE TRANSITION

	public playOut(goTo: string, resolve: () => void) {
		log("> playOut", goTo)
		if (goTo === "SinglePostPage") {
			defaultPlayOutTranslate(this.$root, goTo, resolve)
		} else {
			defaultPlayOut(this.$root, goTo, resolve)
		}
	}

	public playIn(comeFrom: string, resolve: () => void) {
		// transform translateY(0)
		this.$root.style.transform = "translateY(0)"
		defaultPlayIn(this.$root, comeFrom, resolve)
		this.intro.playIn(10)
	}
}
