import debug from "@cher-ami/debug"
import gsap from "gsap"
import { Component } from "~/libs/compose"
import PostSlide from "../postSlide/PostSlide"
import SmoothScrollManager from "~/services/SmoothScrollManager"
import PostHand from "../postHand/postHand"
import postsNavigationService from "~/services/PostsNavigationService"
import { isMobile } from "~/helpers/isMobile"

const componentName = "PostSlider"
const log = debug(`front:${componentName}`)

type TStaticProps = {}

/**
 * @name PostSlider
 */
export default class PostSlider extends Component<TStaticProps> {
	static attrName = componentName

	public gap = 0
	public startOffsetIndex = 0
	public playInOffset = 1
	public loopOffset = 1 // has of the side slides visible on each side

	// TODO: set -1 for anim from the left
	public currentSlideIndex: number

	public currentSlide: PostSlide
	public slideWidth: number
	public translateXOffset: number

	public isAnimating: boolean = false

	public slides: PostSlide[] = this.addAll(PostSlide)

	public hand: PostHand

	public elements = {
		$container: this.find<HTMLDivElement>("container")
	}

	// ----------------------------------------------------------------------------- LIFECYCLE

	mounted() {
		log("> mounted")

		this.slideWidth = this.getSlideWidth()
		this.translateXOffset = this.slideWidth + this.gap
		this.startOffsetIndex = +this.$root.dataset.startIndex
		this.currentSlideIndex = this.startOffsetIndex - this.playInOffset
		this.initSlider()
		this.attachEvents()
	}

	unmounted() {
		this.detachEvents()
	}

	// ----------------------------------------------------------------------------- EVENTS

	attachEvents() {
		// add resize
		window.addEventListener("resize", this.handleResize)
		// Scroll event listener
		window.addEventListener("scroll", this.onScroll)
	}

	detachEvents() {
		// remove resize
		window.removeEventListener("resize", this.handleResize)
		// Scroll event listener
		window.removeEventListener("scroll", this.onScroll)
	}

	// ----------------------------------------------------------------------------- EVENTS ALLBACKS

	handleResize = () => {
		// if slide width has not changed, do nothing
		if (this.slideWidth === this.getSlideWidth()) return
		this.slideWidth = this.getSlideWidth()
		this.translateXOffset = this.slideWidth + this.gap
		this.initSlider()
		log("> handleResize")
	}

	onScroll = () => {
		this.updateSideSlidePositions()
	}

	// ----------------------------------------------------------------------------- SLIDE LOGIC

	initSlider() {
		// Animate the slides to the new position
		const wrapWidth = this.getWrapWidth()
		const translateXOffset = this.translateXOffset
		const loopOffset = this.loopOffset
		gsap.set(
			this.slides.map((s) => s.$root),
			{
				x: (x) => (x - this.currentSlideIndex) * this.translateXOffset,
				modifiers: {
					x: (x, target) => {
						// wrap reset position to loop in both directions
						x = gsap.utils.wrap(
							-translateXOffset - translateXOffset * loopOffset,
							wrapWidth - translateXOffset * loopOffset,
							parseInt(x)
						)
						return x + "px"
					}
				}
			}
		)
		log("> init slider," + this.currentSlideIndex)
	}

	async goTo(targetIndex: number, duration: number = 0.6) {
		if (targetIndex === this.currentSlideIndex) return
		if (this.isAnimating) return
		this.isAnimating = true

		// Get all slides elements
		const slideElements = this.getSlidesElements()

		// Normalize the targetIndex to be within the bounds of the slides array
		const totalSlides = this.slides.length
		targetIndex = Math.abs(((targetIndex % totalSlides) + totalSlides) % totalSlides)

		// Determine the shortest direction to the target index
		const currentIndex = this.currentSlideIndex
		const forwardDistance = (targetIndex - currentIndex + totalSlides) % totalSlides
		const backwardDistance = (currentIndex - targetIndex + totalSlides) % totalSlides

		// Use the shortest distance
		const distance =
			forwardDistance <= backwardDistance ? forwardDistance : -backwardDistance

		// Calculate the new position
		const newPosition = `-=${distance * this.translateXOffset}`

		// Animate the slides to the new position
		const wrapWidth = this.getWrapWidth()
		const translateXOffset = this.translateXOffset
		const loopOffset = this.loopOffset
		const currentSlideIndex = this.currentSlideIndex

		// stop the scroll
		SmoothScrollManager.lenis.stop()

		// reset side slide positions
		this.resetSideSlidePositions()

		// reset current slide content width on mobile
		if (isMobile()) {
			this.resetCurrentSlideContentWidth()
		}

		// set target as current slide
		this.setCurrentSlide(targetIndex)

		this.hand.playOut()
		gsap.delayedCall(0.4, () => {
			this.hand.updateLogo(this.currentSlide.logoSrc)
		})

		// back to top smoothly
		gsap.delayedCall(0.05, () => {
			SmoothScrollManager.lenis.scrollTo(0, {
				force: true,
				duration: 0.5
			})
		})

		// animate
		return gsap.to(slideElements, {
			x: newPosition,
			duration,
			ease: "power2.out",
			modifiers: {
				x: (x, target) => {
					// wrap reset position to loop in both directions
					x = gsap.utils.wrap(
						-translateXOffset - translateXOffset * loopOffset,
						wrapWidth - translateXOffset * loopOffset,
						parseInt(x)
					)
					return x + "px"
				}
			},
			// FIXME : stagger in prev direction
			stagger: function (index, target, list) {
				// Calculate the stagger based on the shortest path to the target
				let forwardIndex = (index - currentSlideIndex + totalSlides) % totalSlides
				let backwardIndex = (currentSlideIndex - index + totalSlides) % totalSlides

				// Choose the shortest path to the target
				let shortestPathIndex =
					forwardIndex <= backwardIndex ? forwardIndex : -backwardIndex
				let direction = forwardIndex <= backwardIndex

				// Get the stagger delay when going left direction (invserse)
				if (!direction) {
					shortestPathIndex = -shortestPathIndex
				}

				// Calculate the stagger delay based on the shortest path index
				const staggerDelay = shortestPathIndex * 0.04
				return staggerDelay
			},
			onComplete: () => {
				this.isAnimating = false
				this.hand.playIn()
				// Allow the scroll to resume after hand appear
				setTimeout(() => {
					SmoothScrollManager.lenis.start()
				}, 300)
			}
		})
	}

	getWrapWidth() {
		return (this.slides.length - 1) * this.translateXOffset
	}

	getSlideWidth() {
		return this.slides[0].$root.offsetWidth
	}

	getSlidesElements() {
		return this.slides.map((s) => s.$root)
	}

	setCurrentSlide(index: number) {
		// set current slide
		this.currentSlideIndex = index
		// set current slide element
		this.currentSlide = this.slides[index]
		// set active
		this.slides.map((s) => (s.isCurrent = false))
		this.currentSlide.isCurrent = true
		// TODO: refacto pass in isCurrent
		// set active class
		this.slides.map((s) => s.$root.classList.remove("active"))
		this.currentSlide.$root.classList.add("active")

		// set root height to the current slide height
		this.updateContainerHeight(this.currentSlide.$root.offsetHeight)

		// update url with the current slide url
		history.pushState(null, "", this.currentSlide.url)

		// listen to resize event from the current slide to update the container height
		this.currentSlide.emitter.on("article:resize", ({ width, height }) => {
			this.updateContainerHeight(height)
		})
		// remove the listener from the previous slide
		this.slides.map((s) => {
			if (s !== this.currentSlide) {
				s.emitter.off("article:resize")
			}
		})
	}

	updateContainerHeight(value: number) {
		this.elements.$container.style.height = value + "px"
	}

	// ----------------------------------------------------------------------------- SCROLL LOGIC

	getLeftSlideContent() {
		const currentIndex = this.currentSlideIndex
		const totalSlides = this.slides.length
		const leftIndex = (currentIndex - 1 + totalSlides) % totalSlides
		return this.slides[leftIndex].elements.$content
	}

	getRightSlideContent() {
		const currentIndex = this.currentSlideIndex
		const totalSlides = this.slides.length
		const rightIndex = (currentIndex + 1) % totalSlides
		return this.slides[rightIndex].elements.$content
	}

	// Function to update slide positions based on scroll
	updateSideSlidePositions() {
		if (this.isAnimating) return
		const leftSlide = this.getLeftSlideContent()
		const rightSlide = this.getRightSlideContent()

		// Get the scroll position as a percentage of the page height
		const scrollPercent = window.scrollY / 200

		// Calculate the translation amount based on the scroll percentage
		const maxTranslation = 33 // Max translation percentage
		const translation = maxTranslation * scrollPercent

		// Calculate rotation for hand from 0 to 1
		const rotation = 1 - scrollPercent

		// Apply the translation to the left and right slides
		;[leftSlide, rightSlide].forEach((slide, index) => {
			// Determine if the slide is on the left or right
			const isLeftSlide = index % 2 === 0 // Example logic for left slide
			const isRightSlide = index % 2 !== 0 // Example logic for right slide

			// Calculate the x position for left and right slides
			let xPosition = 0
			if (isLeftSlide) {
				xPosition = -translation
			} else if (isRightSlide) {
				xPosition = translation
			}

			// Use GSAP to update the position of the slide
			gsap.to(slide, {
				xPercent: xPosition,
				ease: "power2.out",
				duration: 0.2,
				overwrite: "auto"
			})
			// gsap.to("#Main", { rotate: -rotation * 3, duration: 0.2 })

			// increase the width of the post-article__box on mobile when scrolling
			if (isMobile()) {
				gsap.to(".post-slide.active .post-article__box", {
					width: gsap.utils.clamp(80.2, 93, 80.2 + scrollPercent * 7.8) + "vw",
					duration: 0.2
				})
			}
		})
	}

	resetSideSlidePositions() {
		const leftSlide = this.getLeftSlideContent()
		const rightSlide = this.getRightSlideContent()

		;[leftSlide, rightSlide].forEach((slide) => {
			gsap.to(slide, {
				xPercent: 0,
				ease: "power2.out",
				duration: 0.2,
				overwrite: "auto"
			})
		})
	}

	resetCurrentSlideContentWidth() {
		gsap.to(".post-slide.active .post-article__box", {
			width: 80.2 + "vw",
			duration: 0.2
		})
	}

	// ----------------------------------------------------------------------------- ANIMATION

	override playIn(delay): void {
		gsap.delayedCall(delay, () => {
			// this.goTo(this.currentSlideIndex + this.playInOffset, 0.7)
		})
	}
}
