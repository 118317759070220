import debug from "@cher-ami/debug"
import { Component, Stack } from "~/libs/compose"
import { Timeline } from "@wbe/interpol"

const componentName = "Header"
const log = debug(`front:${componentName}`)

type TStaticProps = {}

/**
 * @name Header
 */
export default class Header extends Component<TStaticProps> {
	static attrName = "Header"

	public elements = {
		$container: this.find<HTMLElement>("container"),
		$nav: this.find<HTMLElement>("nav"),
		$logo: this.find<HTMLElement>("title")
	}

	// ----------------------------------------------------------------------------- LIFECYCLE

	mounted() {
		log("> mounted")
	}

	unmounted() {}

	// ----------------------------------------------------------------------------- ANIMATION

	override playIn(delay): void {
		// Create a timeline instance
		const tl = new Timeline()
		;[this.elements.$nav, this.elements.$logo].forEach((el) =>
			tl.add(
				{
					el,
					props: {
						y: [155, 0, "%"]
					},
					duration: 700,
					ease: "power3.out"
				},
				delay
			)
		)
	}
}
